import React from "react";
import { useEffect } from 'react';
import { setItem, isLoggedIn } from "../../services/auth";
import { navigate } from "gatsby";

const qs = require("query-string")

const Callback = ({ location }) => {

    const params = new URLSearchParams(location.search);
    const name = params.get("name");
    const token = params.get("token");
    console.log("params : " + params);
    let user = {
        name: name,
        email: ""
    }

    useEffect(() => {
        setItem("user", JSON.stringify(user));
        setItem("token", JSON.stringify(token));
        window.location.href = '/member/account';
      }, []);
    return <div>Redirect ...</div>
}
export default Callback


